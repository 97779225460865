import { Component, OnInit } from '@angular/core';
import { ViewToggleService } from '../../shared/services/view-toggle.service';
import { BehaviorSubject } from 'rxjs';
import { ViewToggle } from '../../shared/enums/view-toggle.enum';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  public viewToggle = ViewToggle;
  public viewToggleState$: BehaviorSubject<number> = this.viewToggleService.viewToggleState;

  constructor(
    private viewToggleService: ViewToggleService
  ) { }

  ngOnInit(): void {
  }

}
