<div class="container">
  <app-title [subTitle]="'header.navigation.home'"></app-title>
  <app-main-info-widget></app-main-info-widget>
</div>

<div class="carousel-wrapper">
  <div class="container">
    <carousel [isAnimated]="true" [interval]="3000" class="d-none d-md-block">
      <slide *ngFor="let photo of photos">
        <img class="w-100" style="display: block; width: 100%; height: 600px;" [src]="photo.img" [alt]="photo.alt">
      </slide>
    </carousel>
  </div>
</div>
