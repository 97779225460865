import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {ViewToggleService} from './shared/services/view-toggle.service';
import {ViewToggle} from "./shared/enums/view-toggle.enum";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public toggleState$: BehaviorSubject<number> = this.viewToggleService.viewToggleState;
  public viewToggle = ViewToggle;

  constructor(
    private translateService: TranslateService,
    private viewToggleService: ViewToggleService,
  ) {
    translateService.setDefaultLang('ua');
  }

  title = 'MS-STK';
}
