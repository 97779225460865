import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ViewToggleService } from '../../shared/services/view-toggle.service';
import { ViewToggle } from '../../shared/enums/view-toggle.enum';

@Component({
  selector: 'app-doctor-info',
  templateUrl: './doctor-info.component.html',
  styleUrls: ['./doctor-info.component.scss']
})
export class DoctorInfoComponent implements OnInit {
  @Input()
  img = '';

  public viewToggle = ViewToggle;
  public viewToggleState$: BehaviorSubject<number> = this.viewToggleService.viewToggleState;

  constructor(
    public viewToggleService: ViewToggleService,
  ) { }

  ngOnInit(): void {
  }

}
