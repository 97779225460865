import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ViewToggle} from '../enums/view-toggle.enum';

@Injectable({
  providedIn: 'root'
})
export class ViewToggleService {
  viewToggleState: BehaviorSubject<number> = new BehaviorSubject<number>(ViewToggle.dentistry);

  constructor() { }
}
