<app-header></app-header>

<div class="main pt-2" [ngClass]="(toggleState$ | async) === viewToggle.dentistry ? 'dentistry' : 'cosmetology'">

  <div class="router-container">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
