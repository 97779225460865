<div class="container pb-4">
  <div class="row">
    <app-info-container
      class="col-12 col-md-6 col-lg-3 ps-lg-0 py-2"
      [title]="'info.location.title' | translate"
      [description]="'info.location.description' | translate"
    >
      <i class="fa fa-map-marker" aria-hidden="true"></i>
    </app-info-container>

    <app-info-container
      class="col-12 col-md-6 col-lg-3 py-2"
      [title]="'info.contacts.title' | translate"
      [description]="
        ((viewToggleState$ | async) === viewToggle.cosmetology
          ? 'info.contacts.description.cosmetology'
          : 'info.contacts.description.dentistry') | translate
      "
    >
      <i class="fa fa-phone" aria-hidden="true"></i>
    </app-info-container>

    <app-info-container
      class="col-12 col-md-6 col-lg-3 py-2"
      [title]="'info.workingHours.title' | translate"
    >
      <i  class="fa fa-clock-o" aria-hidden="true"></i>
      <div inner-content class="w-100">
        <div class="text-center">
          <div *ngIf="(viewToggleState$ | async) === viewToggle.dentistry; else cosmetology" class="dentistry">
            <p>
              <span>{{ 'info.workingHours.description.dentistry.workingDays_1' | translate }}</span>
              -
              <span>{{ 'info.workingHours.description.dentistry.workingHours_1' | translate }}</span>
            </p>

            <p>
              <span>{{ 'info.workingHours.description.dentistry.workingDays_2' | translate }}</span>
              -
              <span>{{ 'info.workingHours.description.dentistry.workingHours_2' | translate }}</span>
            </p>
          </div>
          <ng-template #cosmetology>
            <p>
              <span>{{ 'info.workingHours.description.cosmetology.workingDays_1' | translate }}</span>
              -
              <span>{{ 'info.workingHours.description.cosmetology.workingHours_1' | translate }}</span>
            </p>

            <p>
              <span>{{ 'info.workingHours.description.cosmetology.workingDays_2' | translate }}</span>
              -
              <span>{{ 'info.workingHours.description.cosmetology.workingHours_2' | translate }}</span>
            </p>
          </ng-template>
        </div>
      </div>
    </app-info-container>

    <app-info-container
      class="col-12 col-md-6 col-lg-3 pe-lg-0 py-2"
      [title]="'info.workingDays.title' | translate"
      [description]="'info.workingDays.description' | translate"
    >
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </app-info-container>

  </div>
</div>
