<div class="container">
  <app-title [subTitle]="'header.navigation.contacts'"></app-title>

  <app-content-wrapper class="py-4 text-center">
    <div class="row">
      <div class="col-12 col-lg-6">
        <p>{{ 'contacts.address.line1' | translate }}</p>
        <p>{{ 'contacts.address.line2' | translate }}</p>
      </div>
      <div class="col-12 col-lg-6">
        <div class="phones-container d-flex justify-content-center">
          <p class="pe-2">{{ 'contacts.phones.title' | translate }}: </p>
          <div *ngIf="(viewToggleState$ | async) === viewToggle.dentistry; else cosmetology" class="dentistry">
            <p>
              <a href="Tel: {{ 'contacts.phones.dentistry.line1' | translate }}">{{ 'contacts.phones.dentistry.line1' | translate }}</a>
            </p>
          </div>
          <ng-template #cosmetology>
            <p>
              <a href="Tel: {{ 'contacts.phones.cosmetology.line1' | translate }}">{{ 'contacts.phones.cosmetology.line1' | translate }}</a>
            </p>
          </ng-template>
        </div>
        <p>
          <a target="_blank" href="https://www.google.com.ua/maps/place/MS+ESTETIC+%D0%A1%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D1%82%D0%B0+%D0%9A%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D0%BA%D0%BB%D1%96%D0%BD%D1%96%D0%BA%D0%B0/@49.8308611,23.9710775,19z/data=!4m5!3m4!1s0x473ae766d3a27613:0x754000f620f1e7e7!8m2!3d49.8309227!4d23.9714536?hl">
            Переглянути на карті
          </a>
        </p>
      </div>
    </div>
  </app-content-wrapper>

  <a target="_blank" href="https://www.google.com.ua/maps/place/MS+ESTETIC+%D0%A1%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D1%82%D0%B0+%D0%9A%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D0%BA%D0%BB%D1%96%D0%BD%D1%96%D0%BA%D0%B0/@49.8308611,23.9710775,19z/data=!4m5!3m4!1s0x473ae766d3a27613:0x754000f620f1e7e7!8m2!3d49.8309227!4d23.9714536?hl">
    <img class="map w-100 my-2" src="assets/map.PNG" alt="Map">
  </a>
</div>
