<footer class="footer">
  <div class="container">
    <div class="text-muted">
      <div class="row">

        <div class="col-12 col-md-5 text-center">
          <p class="pt-4">
            {{ 'contacts.address.line1' | translate }}
          </p>
          <p>
            {{ 'contacts.address.line2' | translate }}
          </p>
        </div>
        <div class="col-12 col-md-5 contacts text-center">
          <div class="pt-4">
            <p>
              {{ 'info.contacts.description.dentistry' | translate }}
            </p>
            <p>
              {{ 'info.contacts.description.cosmetology' | translate }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-2 text-center">
          <div class="social-media pt-4">
            <a href="https://www.instagram.com/ms__estetic/" class="me-2" target="_blank">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="https://www.facebook.com/MSEstetic/" target="_blank">
              <i class="fa fa-facebook-official" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

