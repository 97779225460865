<p class="title-container my-4">
  <span class="title">
    {{(viewSection$ | async) === 1 ?
      ('header.view-toggle.dentistry' | translate)
      : ('header.view-toggle.cosmetology' | translate)}}
  </span>
  <span class="sub-title">
    / {{ subTitle | translate }}
  </span>
</p>
