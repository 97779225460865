import { Component, OnInit } from '@angular/core';
import { PricesService } from './prices.service';
import { ServiceItem } from './service-item';
import {ViewToggle} from '../../shared/enums/view-toggle.enum';
import {BehaviorSubject} from 'rxjs';
import {ViewToggleService} from '../../shared/services/view-toggle.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})

export class PricesComponent implements OnInit {
  public cosmetologyServices: ServiceItem[] = [];
  public dentistryServices: ServiceItem[] = [];
  public toggleState$: BehaviorSubject<number> = this.viewToggleService.viewToggleState;
  public viewToggle = ViewToggle;


  constructor(
    public pricesService: PricesService,
    private viewToggleService: ViewToggleService,
  ) { }

  ngOnInit(): void {
    this.pricesService.getDentistryServices()
      .subscribe((services) => this.dentistryServices = services);

    this.pricesService.getCosmetologyServices()
        .subscribe((services) => this.cosmetologyServices = services);
  }
}
