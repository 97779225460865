import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { MainInfoWidgetComponent } from './components/main-info-widget/main-info-widget.component';
import { InfoContainerComponent } from './components/info-container/info-container.component';
import { ViewTogglerComponent } from './components/view-toggler/view-toggler.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import {FormsModule} from '@angular/forms';
import { TitleComponent } from './components/title/title.component';
import {CardModule} from 'primeng/card';
import { ContentWrapperComponent } from './components/content-wrapper/content-wrapper.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MainInfoWidgetComponent,
    InfoContainerComponent,
    ViewTogglerComponent,
    TitleComponent,
    ContentWrapperComponent,
  ],
    imports: [
        TranslateModule,
        RouterModule,
        CommonModule,
        SelectButtonModule,
        FormsModule,
        CardModule,
    ],
  providers: [],
  exports: [
    HeaderComponent,
    FooterComponent,
    InfoContainerComponent,
    MainInfoWidgetComponent,
    ViewTogglerComponent,
    TitleComponent,
    ContentWrapperComponent,
  ]
})
export class SharedModule { }
