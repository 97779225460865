<div class="container">
  <app-title [subTitle]="'header.navigation.prices'"></app-title>

  <p-accordion *ngIf="(toggleState$ | async) === viewToggle.cosmetology" class="pe-2">
    <p-accordionTab
      *ngFor="let service of cosmetologyServices; let i = index"
      [header]="service.title | translate"
      [selected]="i === 0 ? true : false"
    >
      <app-price-table [services]="service.serviceList"></app-price-table>
    </p-accordionTab>
  </p-accordion>

  <p-accordion *ngIf="(toggleState$ | async) === viewToggle.dentistry" class="pe-2">
    <p-accordionTab
      *ngFor="let service of dentistryServices; let i = index"
      [header]="service.title | translate"
      [selected]="i === 0 ? true : false"
    >
      <app-price-table [services]="service.serviceList"></app-price-table>
    </p-accordionTab>
  </p-accordion>
</div>

