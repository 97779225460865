import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ViewToggleService} from '../../services/view-toggle.service';
import {ViewToggle} from "../../enums/view-toggle.enum";

@Component({
  selector: 'app-view-toggler',
  templateUrl: './view-toggler.component.html',
  styleUrls: ['./view-toggler.component.scss']
})

export class ViewTogglerComponent {
  constructor(
    private translateService: TranslateService,
    private viewToggleService: ViewToggleService,
  ) {
  }

  toggleItems = [
    {
      value: 'header.view-toggle.dentistry'
    },
    {
      value: 'header.view-toggle.cosmetology'
    }
  ];

  selectedView = this.toggleItems[0];

  onViewSelect(event: any): void {
    const index = this.toggleItems.findIndex((item) => item === event.value);
    index === 0 ?
      this.viewToggleService.viewToggleState.next(ViewToggle.dentistry)
      : this.viewToggleService.viewToggleState.next(ViewToggle.cosmetology);
  }
}
