import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceItem } from './service-item';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  constructor(
    private http: HttpClient
  ) { }

  getDentistryServices(): Observable<ServiceItem[]> {
    return this.http.get<{ services: ServiceItem[] }>('../../assets/prices/dentistry-prices.json').pipe(
      map((res) => res.services)
    );
  }

  getCosmetologyServices(): Observable<ServiceItem[]> {
    return this.http.get<{ services: ServiceItem[] }>('../../assets/prices/cosmetology-prices.json').pipe(
        map((res) => res.services)
    );
  }
}
