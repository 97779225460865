<p-table [value]="services">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'prices.table.name' | translate }}</th>
      <th>{{ 'prices.table.price' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-service>
    <tr>
      <td [innerHTML]="service.name"></td>
      <td>{{service.price}}</td>
    </tr>
  </ng-template>
</p-table>
