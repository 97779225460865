import { Component, OnInit } from '@angular/core';
import {ViewToggle} from '../../shared/enums/view-toggle.enum';
import {BehaviorSubject} from 'rxjs';
import {ViewToggleService} from '../../shared/services/view-toggle.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  public viewToggle = ViewToggle;
  public viewToggleState$: BehaviorSubject<number> = this.viewToggleService.viewToggleState;

  public dentistryItems = [
    {
      img: '1',
      desc: 'desc for 1 img'
    },
    {
      img: '2',
      desc: 'desc for 2 img'
    },
    {
      img: '3',
      desc: 'desc for 4 img'
    },
    {
      img: '5',
      desc: 'desc for 5 img'
    },
    {
      img: '6',
      desc: 'desc for 6 img'
    },
    {
      img: '7',
      desc: 'desc for 7 img'
    },
    {
      img: '8',
      desc: 'desc for 8 img'
    },
    {
      img: '9',
      desc: 'desc for 9 img'
    },
    {
      img: '10',
      desc: 'desc for 10 img'
    },
    {
      img: '11',
      desc: 'desc for 11 img'
    },
    {
      img: '12',
      desc: 'desc for 12 img'
    },
    {
      img: '13',
      desc: 'desc for 13 img'
    },
    {
      img: '14',
      desc: 'desc for 14 img'
    },
    {
      img: '15',
      desc: 'desc for 14 img'
    },
  ];

  public cosmetologyItems = [
    {
      img: '1',
      desc: 'desc for 1 img'
    },
    {
      img: '2',
      desc: 'desc for 2 img'
    },
    {
      img: '3',
      desc: 'desc for 3 img'
    },
    {
      img: '4',
      desc: 'desc for 3 img'
    },
  ];

  constructor(
    public viewToggleService: ViewToggleService,
  ) { }

  ngOnInit(): void {
  }

}
