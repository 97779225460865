import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Routes } from '../../helpers/routes';
import {ViewToggle} from '../../enums/view-toggle.enum';
import {BehaviorSubject} from 'rxjs';
import {ViewToggleService} from '../../services/view-toggle.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  routes = Routes;
  selectedLanguage = 'ua';
  viewToggle = ViewToggle;
  viewToggleState$: BehaviorSubject<number> = this.viewToggleService.viewToggleState;

  constructor(
    private translateService: TranslateService,
    private viewToggleService: ViewToggleService,
  ) { }

  ngOnInit(): void {
  }

  setAppLanguage(language: string): void {
    this.selectedLanguage = language;
    this.translateService.setDefaultLang(language);
  }
}
