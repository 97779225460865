import { Component, OnInit } from '@angular/core';
import {ViewToggleService} from '../../services/view-toggle.service';
import {ViewToggle} from '../../enums/view-toggle.enum';

@Component({
  selector: 'app-main-info-widget',
  templateUrl: './main-info-widget.component.html',
  styleUrls: ['./main-info-widget.component.scss']
})
export class MainInfoWidgetComponent implements OnInit {
  public viewToggle = ViewToggle;
  public viewToggleState$ = this.viewToggleService.viewToggleState;

  constructor(
    private viewToggleService: ViewToggleService,
  ) { }

  ngOnInit(): void {
  }

}
