<app-content-wrapper>
  <div class="d-flex flex-column align-items-center">
    <div class="icon">
      <ng-content></ng-content>
    </div>
    <h5 class="title text-center">
      {{ title }}
    </h5>
    <p *ngIf="description" class="description text-center">
      {{ description }}
    </p>
    <ng-content select="[inner-content]"></ng-content>
  </div>
</app-content-wrapper>
