<div class="container">
  <app-title [subTitle]="'header.navigation.aboutUs'"></app-title>

  <div class="description pb-2">

    <app-content-wrapper>
      <h4 class="py-2">{{ 'aboutUs.title' | translate }}</h4>

      <p>
        {{ 'aboutUs.description_1' | translate }}
      </p>

      <p>
        {{ 'aboutUs.description_2' | translate }}
      </p>

      <p>
        {{ 'aboutUs.description_3' | translate }}
      </p>
    </app-content-wrapper>

    <app-doctor-info class="col-12" [img]="'assets/cosm_info_img.jpg'"></app-doctor-info>

    <app-content-wrapper>
      <div class="lists-container row py-4">
        <div class="dentistry-list col-12 col-lg-4">
          <h6>{{ 'aboutUs.lists.list_1.title' | translate }}</h6>
          <ul>
            <li>{{ 'aboutUs.lists.list_1.item_1' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_2' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_3' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_4' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_5' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_6' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_7' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_1.item_8' | translate }}</li>
          </ul>
        </div>

        <div class="cosmetology-list col-12 col-lg-4">
          <h6>{{ 'aboutUs.lists.list_2.title' | translate }}</h6>
          <ul>
            <li>{{ 'aboutUs.lists.list_2.item_1' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_2' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_3' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_4' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_5' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_6' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_7' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_2.item_8' | translate }}</li>
          </ul>
        </div>

        <div class="advantages-list col-12 col-lg-4">
          <h6>{{ 'aboutUs.lists.list_3.title' | translate }}</h6>
          <ul>
            <li>{{ 'aboutUs.lists.list_3.item_1' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_2' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_3' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_4' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_5' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_6' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_7' | translate }}</li>
            <li>{{ 'aboutUs.lists.list_3.item_8' | translate }}</li>
          </ul>
        </div>
      </div>
    </app-content-wrapper>


  </div>
</div>
