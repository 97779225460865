import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  photos = [
    { img: 'assets/2.jpg', alt: 'carousel item 1' },
    { img: 'assets/1.jpg', alt: 'carousel item 2' },
    { img: 'assets/3.jpg', alt: 'carousel item 3' },
    { img: 'assets/4.jpg', alt: 'carousel item 4' },
    { img: 'assets/5.jpg', alt: 'carousel item 5' },
    { img: 'assets/6.jpg', alt: 'carousel item 6' },
    { img: 'assets/7.jpg', alt: 'carousel item 7' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
