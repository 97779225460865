import {Component, Input, OnInit} from '@angular/core';
import {ViewToggleService} from '../../services/view-toggle.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input()
  public subTitle = '';
  public viewSection$ = this.viewToggleService.viewToggleState;

  constructor(
    private viewToggleService: ViewToggleService,
  ) { }

  ngOnInit(): void {
  }

}
