<div class="content info-container py-2">
  <hr>
  <div class="d-flex justify-content-center">
    <p-card
      *ngIf="(viewToggleState$ | async) === viewToggle.dentistry; else cosmetology"
      header="Регеда Степан Михайлович" [style]="{width: '600px', maxWidth: '95vw'}"
      styleClass="p-card-shadow"
    >
      <ng-template pTemplate="header">
        <img alt="Card" src="assets/dent_info_img.jpg">
      </ng-template>
      <p>Регеда Степан Михайлович - лікар стоматолог, кандидат медичних наук, викладач кафедри хірургічної і ортопедичної стоматології ФПДО, співвласник клініки  ''M’S Еstetic''</p>
    </p-card>

    <ng-template #cosmetology>
      <p-card
        header="Регеда-Фурдичко Мар‘яна"
        [style]="{width: '600px', maxWidth: '95vw'}"
        styleClass="p-card-shadow"
      >
        <ng-template pTemplate="header">
          <img alt="Card" src="assets/cosm_info_img.jpg">
        </ng-template>
        <p>Регеда-Фурдичко Мар‘яна-лікар-дерматокосметолог,доктор медичних наук,співвласниця клініки  ''M’S Еstetic''.
          Працювала у ЛОкШВД у стаціонарному відділенні з пацієнтами,які мали дерматологічні захворювання!
          Тому,надам кваліфіковану консультацію і лікування( дерматити всіх видів,екзема,вугрева хвороба,псоріаз та інші)</p>
      </p-card>
    </ng-template>
  </div>
  <hr>
</div>
