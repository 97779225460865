<p-selectButton
  [options]="toggleItems"
  [(ngModel)]="selectedView"
  [style]="{ opacity: '0.85' }"
  optionLabel="value"
  (onChange)="onViewSelect($event)"
>
  <ng-template let-item>
    <span [class]="item.value">{{item.value | translate}}</span>
  </ng-template>
</p-selectButton>
