<header>
  <div class="wrapper d-flex flex-column">
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div class="container nav-container">
        <a class="navbar-brand" href="#">
          <img class="logo" src="assets/logo.png" alt="logo">
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse d-lg-flex text-center justify-content-between"
          id="navbarSupportedContent"
        >

          <hr class="secondary d-lg-none">

          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                [routerLink]="routes.home"
                [routerLinkActive]="['active', '']"
              >
                {{ 'header.navigation.home' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                [routerLink]="routes.aboutUs"
                [routerLinkActive]="['active', '']"
              >
                {{ 'header.navigation.aboutUs' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                [routerLink]="routes.prices"
                [routerLinkActive]="['active', '']"
              >
                {{ 'header.navigation.prices' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                [routerLink]="routes.contacts"
                [routerLinkActive]="['active', '']"
              >
                {{ 'header.navigation.contacts' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                [routerLink]="routes.portfolio"
                [routerLinkActive]="['active', '']"
              >
                {{ 'header.navigation.portfolio' | translate }}
              </a>
            </li>
          </ul>

          <hr class="d-lg-none">

          <div class="contacts">
            <p class="mb-1">
                <span>
                  {{ 'contacts.phones.title' | translate }}:
                </span>
              <a
                *ngIf="(viewToggleState$ | async) === viewToggle.dentistry"
                href="Tel: {{ 'contacts.phones.dentistry.line1' | translate }}"
              >
                  {{ 'contacts.phones.dentistry.line1' | translate }}
                </a>
              <a
                *ngIf="(viewToggleState$ | async) === viewToggle.cosmetology"
                href="Tel: {{ 'contacts.phones.cosmetology.line1' | translate }}"
              >
                  {{ 'contacts.phones.cosmetology.line1' | translate }}
              </a>
            </p>
            <span>{{ 'contacts.address.line2' | translate }}</span>
          </div>

          <div class="items d-flex justify-content-center">

            <div class="social-media me-md-5">
              <a href="https://www.instagram.com/ms__estetic/" class="me-2" target="_blank">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href="https://www.facebook.com/MSEstetic/" target="_blank">
                <i class="fa fa-facebook-official" aria-hidden="true"></i>
              </a>
            </div>

            <div *ngIf="false" class="language-container d-flex align-items-center ml-2">
              <img
                (click)="setAppLanguage('ua')"
                [ngClass]="selectedLanguage === 'ua' ? 'border border-primary border-3' : ''"
                class="me-2 mt-1"
                src="assets/ua.svg"
                alt="ukrainian language">
              <img
                (click)="setAppLanguage('en')"
                [ngClass]="selectedLanguage === 'en' ? 'border border-primary border-3' : ''"
                class="mt-1"
                src="assets/gb.svg"
                alt="english language">
            </div>
          </div>
        </div>
      </div>
      <div class="view-toggle">
        <app-view-toggler></app-view-toggler>
      </div>
    </nav>

  </div>
</header>
