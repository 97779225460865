<div class="container">
  <app-title [subTitle]="'header.navigation.portfolio'"></app-title>
  <div *ngIf="(viewToggleState$ | async) === viewToggle.dentistry" class="dentistry">
    <div class="row">
      <p-card *ngFor="let item of dentistryItems" class="col-12 col-lg-6 py-2">
        <img class="portfolio-image" [src]="'assets/portfolio/dentistry/'+item.img+'.jpg'" [alt]="item.desc">
      </p-card>
    </div>
  </div>

  <div *ngIf="(viewToggleState$ | async) === viewToggle.cosmetology" class="cosmetology">
    <div class="row">
      <p-card *ngFor="let item of cosmetologyItems" class="col-12 col-lg-6 py-2">
        <img class="portfolio-image" [src]="'assets/portfolio/cosmetology/'+item.img+'.jpg'" [alt]="item.desc">
      </p-card>
    </div>
  </div>
</div>
